@font-face {
	font-family: 'Ruble';
	src: url('../fonts/alsrubl-webfont.eot');
	src: url('../fonts/alsrubl-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/alsrubl-webfont.woff2') format('woff2'),
	url('../fonts/alsrubl-webfont.woff') format('woff'),
	url('../fonts/alsrubl-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'blogger_sans';
	src: url('../fonts/blogger_sans-webfont.eot');
	src: url('../fonts/blogger_sans-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/blogger_sans-webfont.woff2') format('woff2'),
	url('../fonts/blogger_sans-webfont.woff') format('woff'),
	url('../fonts/blogger_sans-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'bitter';
	src: url('../fonts/bitter-bold-webfont.woff2') format('woff2'),
	url('../fonts/bitter-bold-webfont.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'bitter';
	src: url('../fonts/bitter-bolditalic-webfont.woff2') format('woff2'),
	url('../fonts/bitter-bolditalic-webfont.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'bitter';
	src: url('../fonts/bitter-italic-webfont.woff2') format('woff2'),
	url('../fonts/bitter-italic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'bitter';
	src: url('../fonts/bitter-regular-webfont.woff2') format('woff2'),
	url('../fonts/bitter-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}